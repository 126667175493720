import apiResponse from "../models/apiResponse"
import reducerState from "../models/reducerState"
import Tariff from "../models/tariff"

export enum ActionType{
    TARIFF_GETS     = 'TARIFF_GETS',
    TARIFF_GET      = 'TARIFF_GET',
    TARIFF_DELETE   = 'TARIFF_DELETE',
    TARIFF_LOADING  = 'TARIFF_LOADING',
    TARIFF_ERROR    = 'TARIFF_ERROR'
}

interface actionGets
{
    type: ActionType.TARIFF_GETS
}

interface actionGet
{
    type: ActionType.TARIFF_GET
}

interface actionDelete
{
    type: ActionType.TARIFF_DELETE
}

interface actionLoading
{
    type: ActionType.TARIFF_LOADING
}

interface actionError
{
    type: ActionType.TARIFF_ERROR
}

export type Action = actionGets | actionGet | actionDelete | actionLoading | actionError

export type State = reducerState & apiResponse<Tariff[]>;

export const initialState: State =
{
    type: undefined,
    data: undefined,
    loading: false,
    success: true,
    errors: [],
}
