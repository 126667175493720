import { getTariffByUUID, getTariffs, getServerTariffs, deleteTariff } from '../api/tariffAPI';
import { ActionType } from '../types/actions/tariff'
import Item from '../types/models/item';
import Server from '../types/models/server';
import Tariff from '../types/models/tariff';

const getTariffsAction = (page?: number, item?: Item["name"], date?: string) => (dispatch: any) =>
{
    dispatch({
        loading: true,
        type: ActionType.TARIFF_LOADING
    })
    getTariffs(page, item, date).then((response) => {
        const tariffResponse = response.data as Tariff[]
        dispatch({
            loading: false,
            errors: [],
            type: ActionType.TARIFF_GETS,
            ...tariffResponse
        });
    }).catch((exception) => {
        delete exception.response.data.data
        dispatch({
            loading: false,
            type: ActionType.TARIFF_ERROR,
            ...exception.response.data
        })
    });
}

const getServerTariffsAction = (server: Server["id"], page?: number) => (dispatch: any) =>
{
    dispatch({
        loading: true,
        type: ActionType.TARIFF_LOADING
    })
    getServerTariffs(server, page).then((response) => {
        const tariffResponse = response.data as Tariff[]
        dispatch({
            loading: false,
            errors: [],
            type: ActionType.TARIFF_GETS,
            ...tariffResponse
        });
    }).catch((exception) => {
        delete exception.response.data.data
        dispatch({
            loading: false,
            type: ActionType.TARIFF_ERROR,
            ...exception.response.data
        })
    });
}


const getTariffByUUIDAction = (uuid: string) => (dispatch: any) =>
{
    dispatch({
        loading: true,
        type: ActionType.TARIFF_LOADING
    })
    getTariffByUUID(uuid).then((response) => {
        const tariffResponse = response.data.data as Tariff
        dispatch({
            loading: false,
            errors: [],
            type: ActionType.TARIFF_GET,
            data: [tariffResponse]
        });
    }).catch((exception) => {
        delete exception.response.data.data
        dispatch({
            loading: false,
            type: ActionType.TARIFF_ERROR,
            ...exception.response.data
        })
    });
}

const deleteTariffAction = (id: Tariff["id"]) => (dispatch: any) =>
{
    dispatch({
        loading: true,
        type: ActionType.TARIFF_LOADING
    })
    deleteTariff(id).then((response) => {
        dispatch({
            loading: false,
            errors: [],
            type: ActionType.TARIFF_DELETE,
        });
    }).catch((exception) => {
        delete exception.response.data.data
        dispatch({
            loading: false,
            type: ActionType.TARIFF_ERROR,
            ...exception.response.data
        })
    });
}

export { getTariffsAction, getServerTariffsAction, getTariffByUUIDAction, deleteTariffAction }