import { useState, useEffect } from 'react'
import { Button, message } from 'antd'
import useVPNBot from '../../../hooks/useVPNBot'

import ItemsServerModal from '../../Modal/ItemsServerModal'
import Server from '../../../types/models/server'
import Item from '../../../types/models/item'

interface IFields
{
    id: number,
    servers: Array<string>
}

const ItemsServerMenu = () =>
{
    const { useItem } = useVPNBot()
    const { updateItem, items, item, errors, success, loading } = useItem

    const [messageApi, contextHolder] = message.useMessage();
    const [visible, setVisible] = useState(false)
    const [fields, setFields] = useState<IFields>({
        id: 0,
        servers: [],
    })

    useEffect(() => {
        messageApi.destroy()
        errors?.map((value: string, index: number) => {
            messageApi.open({
                type: 'error',
                content: value,
            });
        });
    }, [errors])

    useEffect(() => setVisible(success ? false : visible), [loading])

    const onFieldChange = (name: string, value: any) =>
    {
        setFields({
            ...fields,
            [name]: value
        })
    }

    const addItemServer = () => updateItem({id: fields.id} as Item, fields.servers)

    const onAddItemServer = () =>
    {
        setFields({
            id: item?.id ?? 0,
            servers: (item?.servers ?? []).map((server: Server, index: number) => server.name)
        })
        setVisible(true)
    }

    return (
    <>
        {contextHolder}
        <Button type="primary" onClick={onAddItemServer}>Add server</Button>
        <ItemsServerModal
            title='Add server'
            buttonTitle='Add'
            source={fields}
            show={visible}
            handleApply={addItemServer}
            handleShow={(state: boolean) => setVisible(state)}
            handleSource={onFieldChange}
        />
    </>
    )
}

export default ItemsServerMenu