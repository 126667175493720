import { combineReducers } from 'redux'
import promocodeReducer from './promocodeReducer'
import userReducer from './userReducer'
import purchaseReducer from './purchaseReducer'
import itemReducer from './itemReducer'
import serverReducer from './serverReducer'
import keyReducer from './keyReducer'
import deadlineReducer from './deadlineReducer'
import tabReducer from './tabReducer'
import countryReducer from './countryReducer'
import clientReducer from './clientReducer'
import tariffReducer from './tariffReducer'

const reducers = combineReducers({
    promocodes: promocodeReducer,
    users: userReducer,
    purchases: purchaseReducer,
    items: itemReducer,
    servers: serverReducer,
    keys: keyReducer,
    deadline: deadlineReducer,
    tab: tabReducer,
    countries: countryReducer,
    clients: clientReducer,
    tariffs: tariffReducer,
});

export default reducers;

export type RootState = ReturnType<typeof reducers>;