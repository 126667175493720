import { Action, ActionType, initialState, State } from '../types/actions/deadline';

const deadlineReducer = (state: State = initialState, action: Action): State =>
{
    //console.log({...state, ...action})
    switch(action.type)
    {
        case ActionType.DEADLINE_DELETE:
        case ActionType.DEADLINE_ERROR:
        case ActionType.DEADLINE_GET:
        case ActionType.DEADLINE_GETS:
        case ActionType.DEADLINE_LOADING:
        case ActionType.DEADLINE_PUT:
        case ActionType.DEADLINE_PUTS:
            return {...state, ...action};
        default:
            return state;
    }
}

export default deadlineReducer;