import useUser from "../hooks/useUser";

const TokenConfig = () => {
    const token = localStorage.getItem("token")

    return {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    }

}

export default TokenConfig;