import { useContext, useEffect, useState } from 'react'
import { useAppDispatch } from './useAppDispatch'
import { useAppSelector } from "./useAppSelector"
import { getPromocodes as getPromocodesAction, addPromocodeAction, deletePromocodeAction, editPromocodeAction } from '../actions/promocode'
import usePagination from './usePagination'
import Meta from '../types/models/meta'
import Promocode from '../types/models/promocode'
import { ActionType } from '../types/actions/promocode'
import { FilterContext } from '../providers/FilterProvider'

const usePromocode = () => {

    const getPromocodes = (page?: number) =>
    {
        dispatch(getPromocodesAction(page ?? getPagination()))
    }

    const addPromocode = (promocode: Promocode) =>
    {
        dispatch(addPromocodeAction(promocode))
    }

    const deletePromocode = (id: Promocode["id"]) =>
    {
        dispatch(deletePromocodeAction(id))
    }

    const editPromocode = (promocode: Promocode) =>
    {
        dispatch(editPromocodeAction(promocode))
    }

    const { usePagination } = useContext(FilterContext)
    const { getPagination, clearPagination, page } = usePagination
    const selector = useAppSelector((state) => state.promocodes)
    const dispatch = useAppDispatch()

    const [promocodes, setPromocodes] = useState<Promocode[]>(selector.data ?? [])
    const [meta, setMeta] = useState<Meta|undefined>(selector?.meta)
    const [loading, setLoading] = useState(selector.loading)
    const [errors, setErrors] = useState(selector.errors)
    const [success, setSuccess] = useState(selector.success)

    useEffect(() => {
        setPromocodes(selector.data ?? []);
        setMeta(selector.meta)
        setLoading(selector.loading)
        setErrors(selector.errors)
        setSuccess(selector.success)
        switch (selector.type)
        {
            case ActionType.PROMOCODE_PUT:
            case ActionType.PROMOCODE_DELETE:
            case ActionType.PROMOCODE_POST:
                getPromocodes()
        }
    }, [selector]);

    useEffect(() => {
        if (meta && page > meta.last_page)
        {
            clearPagination()
            getPromocodes()
        }
    }, [page])

    return {
        getPromocodes,
        addPromocode,
        deletePromocode,
        editPromocode,
        promocodes,
        loading,
        success,
        errors,
        meta
    }
}

export default usePromocode;