import {useState, useEffect} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { setTabKey } from '../actions/tab';
import NavItems from "../components/NavItems"
import TabItem from '../types/models/tabItem';
import Keys from '../components/Tab/KeysTab'
import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';


const useTab = () =>
{
    const getItem = (key: TabItem["key"]): TabItem =>
    {
        return items.filter((item) => item.key == key)[0]
    }

    const getItemByLink = (link: TabItem["link"]): TabItem =>
    {
        return items.filter((item) => link?.includes(item.link ?? ''))[0]
    }

    const getKey = () =>
    {
        return selector?.data?.key ?? (getItemByLink(location.pathname)?.key ?? "1")
    }

    const selector = useAppSelector((state) => state.tab)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const items = NavItems()
    const item = getItem(getKey())

    const [key, setKey] = useState<TabItem["key"]>(item.key)
    const [label, setLabel] = useState<TabItem["label"]>(item.label)
    const [link, setLink] = useState<TabItem["link"]>(item.link)

    const setTab = (item: TabItem) =>
    {
        dispatch(setTabKey(item))
        if (item.link && !location.pathname.includes(item.link))
            navigate(item.link)
    }

    useEffect(() => {
        const key = getKey()
        const item = getItem(key) ?? getItemByLink(location.pathname)
        setKey(key)
        setLabel(item.label)
        setLink(item.link)

    }, [selector])

    return {
        setLabel,
        getItem,
        setTab,
        item,
        key,
        label,
        link
    }
}

export default useTab