import { Action, ActionType, initialState, State } from '../types/actions/key';

const keyReducer = (state: State = initialState, action: Action): State =>
{
    //console.log({...state, ...action})
    switch(action.type)
    {
        case ActionType.KEY_DELETE:
        case ActionType.KEY_ERROR:
        case ActionType.KEY_POST:
        case ActionType.KEY_GET:
        case ActionType.KEY_GETS:
        case ActionType.KEY_LOADING:
        case ActionType.KEY_PUT:
            return {...state, ...action};
        default:
            return state;
    }
}

export default keyReducer;