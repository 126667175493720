import { useEffect, useState, useContext } from 'react'
import { Table, Popconfirm, Button, Form, Input } from "antd"
import { EditOutlined, DeleteOutlined, StopOutlined } from '@ant-design/icons'
import { Helmet } from 'react-helmet'
//Models
import Server from '../../../types/models/server'
//Components
import Spinner from '../../Spinner'
//Provider
import useVPNBot from '../../../hooks/useVPNBot'

import './index.scss'


//TODO Add IColumns interface for table from newDataSource

const ItemsServerTab = () =>
{
    //-------------------------------------------------------------------------------
    //                                  STATES
    //-------------------------------------------------------------------------------

    const { useItem, useServer } = useVPNBot()
    const { items, item } = useItem
    const [dataSource, setDataSource] = useState<any[]>()

    //-------------------------------------------------------------------------------
    //                                  EFFECTS
    //-------------------------------------------------------------------------------

    useEffect(() => {
        const newDataSource: Array<any> = [];
            item?.servers?.map((server: Server, index: number) => {
                newDataSource.push({
                    key:        index,
                    id:         item.id,
                    uuid:       item.uuid,
                    server_id:  server.id,
                    short_name: server.short_name,
                    available:  server.available,
                    purchased:  server.purchased,
                })
            })
        setDataSource(newDataSource)
    }, [items])

    //-------------------------------------------------------------------------------
    //                                  HANDLERS
    //-------------------------------------------------------------------------------

    const onItemServerDelete = (id: number) =>
    {
        //deleteItem(id)
    }

    //-------------------------------------------------------------------------------
    //                                  TABLE COLUMNS
    //-------------------------------------------------------------------------------

    const columns = [
        {
            title: 'Short name',
            dataIndex: 'short_name',
            key: 'short_name',
            render: (text: any, record: any) => <a href={`/items/${record.id}/${record.server_id}`}>{text}</a>
        },
        {
            title: 'Purchased',
            dataIndex: 'purchased',
            key: 'price',
        },
        {
            title: 'Available',
            dataIndex: 'available',
            key: 'available',
            render: (text: any) => <>{text == -1 ? '∞' : text }</>
        },
        {
            title: 'Actions',
            key: 'id',
            width: 150,
            render: (text: any, record: any) => <>
                {/*<Popconfirm
                    placement="top"
                    title={"Are you sure to delete this server?"}
                    onConfirm={() => onItemServerDelete(record.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <DeleteOutlined className='delete-icon'/>
                </Popconfirm>*/}
            </>
        }
    ];

    //-------------------------------------------------------------------------------
    //                                  RENDER
    //-------------------------------------------------------------------------------

    return (
        <>
            <Helmet>
                <title>Items - VPNBot Admin</title>
            </Helmet>
            {
                (useItem.loading || useServer.loading) ?
                    <div className='flex justify-items-center items-center flex-col w-full'>
                        <Spinner/>
                    </div>
                :
                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        className={"items__tab"}
                        pagination={false}
                    />
            }
        </>
    )
}

export default ItemsServerTab