import { AppThunk } from '../store'
import { ActionType } from '../types/actions/item'
import { addItem, deleteItem, updateItem, getItems, getItem } from '../api/itemAPI'
import Item from '../types/models/item'
import Server from '../types/models/server'

const addItemAction = (item: Item, servers: Array<Server["name"]>): AppThunk => (dispatch: any) =>
{
    dispatch({
        loading: true,
        type: ActionType.ITEM_LOADING
    })
    addItem(item, servers).then((response) => {
        dispatch({
            loading: false,
            type: ActionType.ITEM_POST,
            success: response.data.success,
            errors: []
        })
    }).catch((exception) => {
        dispatch({
            loading: false,
            type: ActionType.ITEM_ERROR,
            errors: [exception.response.data.errors ?? exception.message],
            success: false
        })
    })
}

const getItemsAction = (page?: number): AppThunk => (dispatch: any) =>
{
    dispatch({
        loading: true,
        type: ActionType.ITEM_LOADING
    })
    getItems(page).then((response) => {
        dispatch({
            loading: false,
            type: ActionType.ITEM_GETS,
            data: response.data.data,
            meta: response.data.meta
        })
    }).catch((exception) => {
        dispatch({
            loading: false,
            type: ActionType.ITEM_ERROR,
            errors: [exception.message]
        })
    })
}

const getItemAction = (id: number): AppThunk => (dispatch: any) =>
{
    dispatch({
        loading: true,
        type: ActionType.ITEM_LOADING
    })
    getItem(id).then((response) => {
        dispatch({
            loading: false,
            type: ActionType.ITEM_GETS,
            data: [response.data.data],
            meta: response.data.meta
        })
    }).catch((exception) => {
        dispatch({
            loading: false,
            type: ActionType.ITEM_ERROR,
            errors: [exception.message]
        })
    })
}

const deleteItemAction = (id: number): AppThunk => (dispatch: any) =>
{
    dispatch({
        loading: true,
        type: ActionType.ITEM_LOADING
    })
    deleteItem(id).then((response) => {
        dispatch({
            loading: false,
            errors: [],
            type: ActionType.ITEM_DELETE,
        });
    }).catch((exception) => {
        delete exception.response.data.data
        dispatch({
            loading: false,
            type: ActionType.ITEM_ERROR,
            ...exception.response.data
        })
    });
}

const updateItemAction = (item: Item, servers: Array<Server["name"]>): AppThunk => (dispatch: any) =>
{
    dispatch({
        loading: true,
        type: ActionType.ITEM_LOADING
    })
    updateItem(item, servers).then((response) => {
        dispatch({
            loading: false,
            errors: [],
            type: ActionType.ITEM_PUT,
        });
    }).catch((exception) => {
        delete exception.response.data.data
        dispatch({
            loading: false,
            type: ActionType.ITEM_ERROR,
            ...exception.response.data
        })
    });
}

export { getItemsAction, getItemAction, addItemAction, deleteItemAction, updateItemAction }