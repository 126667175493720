import { Button, Col, Form, Modal, Row, Select } from "antd"
import { useEffect } from "react"
import useVPNBot from "../../../hooks/useVPNBot"
import Server from "../../../types/models/server"

interface ISource
{
    servers: Array<string>,
}

interface IProps
{
    title: string,
    buttonTitle: string,
    show: boolean,
    handleShow: ((state: boolean) => any)
    source: ISource,
    handleSource: ((name: string, value: any) => any),
    handleApply: (() => void)
}


const ItemsServerModal = ({title, buttonTitle, show, handleShow, source, handleSource, handleApply}: IProps) =>
{
    const { useItem, useServer } = useVPNBot()
    const { item } = useItem
    const { servers, getServers } = useServer
    const { Option } = Select
    const [form] = Form.useForm()

    useEffect(getServers, [])
    useEffect(() => form.setFieldsValue(source), [source])

    return (
        <Modal
            title={title}
            centered
            open={show}
            onOk={form.submit}
            onCancel={() => handleShow(false)}
            footer={[
                <Button key="cancel" onClick={() => handleShow(false)}>
                    Cancel
                </Button>,
                <Button form={title} key="submit" htmlType="submit">
                    {buttonTitle}
                </Button>
            ]}
        >
            <Form
                name={title}
                autoComplete="off"
                layout="vertical"
                id={title}
                form={form}
                onFinish={handleApply}
            >
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label="Servers"
                            name="servers"
                            rules={[{ required: true, message: 'Please select servers' }]}
                            initialValue={item?.servers?.map((server: Server, index: number) => {return {label: server.name, value: server.name}})}
                        >
                            <Select
                                mode="multiple"
                                onChange={(value: any) => handleSource('servers', value)}
                            >
                                {servers?.map((server: Server, id: number) =>
                                    <Option value={server.name} text={server.name} key={id}>
                                        <span>{server.name}</span><span className="float-right"></span>
                                    </Option>
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>)
}

export default ItemsServerModal