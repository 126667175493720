import Item from '../types/models/item';
import Purchase from '../types/models/purchase';
import {request, telegramRequest} from './index'
import TokenConfig from './token'

const getPurchases = (item?: Item["name"], page?: number, date?: string) => {
    return request.get('purchases', {headers: TokenConfig().headers, params: {page: page, item: item, date: date}});
}

const getDeadlines = (page?: number, inactive?: boolean, begin_date?: string, end_date?: string) => {
    return request.get('purchases/deadlines', {headers: TokenConfig().headers, params: {
        page: page,
        begin_date: begin_date,
        end_date: end_date,
        inactive: inactive ? '' : undefined
    }})
}

const updateDeadline = (uuid: string, active: boolean, page: number, inactive: boolean, begin_date?: string, end_date?: string) => {
    return request.put(`purchases/${uuid}`, {
        active: active,
        page: page,
        inactive: inactive ? '' : undefined,
        begin_date: begin_date,
        end_date: end_date
    }, TokenConfig())
}

interface IMassUpdatePayload
{
    uuid: string,
    active: boolean,
}

const massUpdateDeadline = (deadlines: Purchase[], page: number, inactive: boolean, begin_date?: string, end_date?: string) => {
    const payload: IMassUpdatePayload[] = [];
    deadlines.map((deadline: Purchase) => payload.push({
        uuid: deadline.uuid,
        active: deadline.active,
    }))
    return request.put(`purchases`, {
        deadlines: payload,
        page: page,
        inactive: inactive ? '' : undefined,
        begin_date: begin_date,
        end_date: end_date
    }, TokenConfig())
}

const addPurchase = (nickname: string, key: string) => {
    return request.post(`purchases`, {
        client: nickname,
        key: key,
    }, TokenConfig())
}

const getPurchaseByTrace = (trace: string) => {
    return telegramRequest.get(`purchases/${trace}`, {headers: TokenConfig().headers});
}

export { getPurchases, getDeadlines, updateDeadline, massUpdateDeadline, addPurchase, getPurchaseByTrace }