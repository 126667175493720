import { Action, ActionType, initialState, State } from '../types/actions/auth';

const userReducer = (state: State = initialState, action: Action): State =>
{
    switch(action.type)
    {
        case ActionType.USER_LOADING:
        case ActionType.USER_AUTH:
        case ActionType.USER_LOGOUT:
        case ActionType.USER_REFRESH:
        case ActionType.USER_ERROR:
            return {...state, ...action};
        default:
            return state;
    }
}

export default userReducer;