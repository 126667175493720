import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { Breadcrumb, Layout, Skeleton } from "antd"
import { Helmet } from "react-helmet"
import { Content } from "antd/es/layout/layout"

import Nav from "../../components/Nav"
import Header from "../../components/Header"
import ItemsServerTab from "../../components/Tab/ItemsServerTab"
import ItemsServerMenu from "../../components/Menu/ItemsServerMenu"
import BreadcrumbLoader from "../../components/BreadcrumbLoader"

import useVPNBot from "../../hooks/useVPNBot"


const  ItemsServerPage = () => {

    //-------------------------------------------------------------------------------
    //                                  STATES
    //-------------------------------------------------------------------------------

    const { useItem } = useVPNBot()
    const { item, getItem, loading } = useItem
    const { item_id } = useParams()
    const breadcrumbs = [
        {title: 'Items', href: '/items'},
        {title: item?.name, /*href: `/items/${item?.id}`*/}
    ]

    //-------------------------------------------------------------------------------
    //                                  EFFECTS
    //-------------------------------------------------------------------------------

    useEffect(() => {
        if (item_id)
            getItem(parseInt(item_id))
    }, [item_id])

    //-------------------------------------------------------------------------------
    //                                  RENDER
    //-------------------------------------------------------------------------------

    return (
        <>
            <Helmet>
                <title>{`${item?.name ?? 'Item'} servers`} - VPNBot Admin</title>
            </Helmet>
            <Layout style={{ minHeight: '100vh' }}>
                <Header/>
                <Layout>
                    <Nav/>
                    <Layout className="site-layout">
                        <div className="tab__menu">
                            <h1 className="tab__title">Servers</h1>
                            <ItemsServerMenu/>
                        </div>
                        <Content className="bg-[#f0f2f5]" style={{ margin: '0 16px' }}>
                            <div className='mt-[32px] p-[24px] bg-white rounded-[4px]'>
                                <ItemsServerTab/>
                            </div>
                        </Content>
                    </Layout>
                </Layout>
            </Layout>
        </>
    )
}

export default ItemsServerPage;