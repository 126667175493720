import Item from '../types/models/item'
import Server from '../types/models/server'
import {request} from './index'
import TokenConfig from './token'

const getPage = (server?: number, page?: number) =>
{
    return request.get('keys', {
        headers: TokenConfig().headers,
        params: {
            server: server,
            page: page
        }
    })
}

const deleteKey = (id: number) =>
{
    return request.delete(`keys/${id}`, TokenConfig());
}

const changeKey = (id: number, name: string, server: Server["name"]) =>
{
    return request.put(`keys/${id}`, {
        name: name,
        server: server
    }, TokenConfig())
}

const addKey = (amount: number, serverId: Server["id"]) =>
{
    return request.post('keys', {
        amount: amount,
        server_id: serverId
    }, TokenConfig())
}

export { getPage, deleteKey, changeKey, addKey }