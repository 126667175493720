import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { store } from './store'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import { VPNBotProvider } from './providers/VPNBotProvider'

//-------------------------------------------------------------------------------
//                                  PAGES
//-------------------------------------------------------------------------------

import Login from './pages/Login'
import Dashboard from './pages/Dashboard'
import GetKey from './pages/GetKey'
//Tab pages
import ItemsPage from './pages/ItemsPage'
import ItemsServerPage from './pages/ItemsServerPage'
import ItemsKeyPage from './pages/ItemsKeyPage'
import PurchasePage from './pages/PurchasePage'
import PromocodesPage from './pages/PromocodesPage'
import ServersPage from './pages/ServersPage'
import KeysPage from './pages/KeysPage'

//-------------------------------------------------------------------------------
//                                  STYLES
//-------------------------------------------------------------------------------

import './index.css'
import ClientsPage from './pages/ClientsPage'
import { FilterProvider } from './providers/FilterProvider'

const configTheme = {
  token: {
    /*colorTextBase: '#ffffff',
    fontFamily: 'Cera Pro',
    colorPrimary: '#FBFBFB',
    colorBorder: '#e4e4e4',
    colorError: '#b10000',
    colorErrorHover: '#b10000',
    //colorPrimaryHover: '#989898',
    colorBgBase: '#EFEFEF'*/
  },
}

//-------------------------------------------------------------------------------
//                                  ROUTES
//-------------------------------------------------------------------------------

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Provider store={store}>
    <ConfigProvider theme={configTheme} >
      <BrowserRouter>
        <FilterProvider>
          <VPNBotProvider>
            <Routes>
                <Route path='*' element={<Dashboard/>}></Route>
                <Route path='/clients' element={<ClientsPage/>}></Route>
                <Route path='/items' element={<ItemsPage/>}></Route>
                <Route path='/items/:item_id' element={<ItemsServerPage/>}></Route>
                <Route path='/items/:item_id/:server_id' element={<ItemsKeyPage/>}></Route>
                <Route path='/payments' element={<PurchasePage/>}></Route>
                <Route path='/promocodes' element={<PromocodesPage/>}></Route>
                <Route path='/servers' element={<ServersPage/>}></Route>
                <Route path='/servers/:server_id' element={<KeysPage/>}></Route>
                <Route path='/login' element={<Login/>}></Route>
                <Route path='/key' element={<GetKey/>}></Route>
            </Routes>
          </VPNBotProvider>
        </FilterProvider>
      </BrowserRouter>
    </ConfigProvider>
  </Provider>
);