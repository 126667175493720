import { useContext, useEffect, useState } from 'react'
import { useAppDispatch } from "./useAppDispatch"
import { FilterContext } from '../providers/FilterProvider'
import { getKeysAction, deleteKeyAction, changeKeyAction, addKeyAction } from "../actions/key"
import { useAppSelector } from "./useAppSelector"
import { ActionType } from '../types/actions/key'
import Key from '../types/models/key'
import Server from '../types/models/server'


const useKey = () =>
{

    const getKeys = (server?:number, page?: number) =>
    {
        dispatch(getKeysAction(server, page ?? getPagination()))
    }

    const deleteKey = (id: number) =>
    {
        dispatch(deleteKeyAction(id))
    }

    const updateKey = (id: number, name: Key["name"], server: Server["name"]) =>
    {
        dispatch(changeKeyAction(id, name, server))
    }

    const addKey = (amount: number, serverId: Server["id"]) =>
    {
        dispatch(addKeyAction(amount, serverId))
    }

    const dispatch = useAppDispatch()
    const selector = useAppSelector((state) => state.keys)
    const { usePagination } = useContext(FilterContext)
    const { getPagination } = usePagination

    const [keys, setKeys] = useState<Key[]>(selector.data ?? [])
    const [success, setSuccess] = useState(selector.success)
    const [errors, setErrors] = useState(selector.errors)
    const [loading, setLoading] = useState(selector.loading)
    const [meta, setMeta] = useState(selector.meta)

    useEffect(() => {
        setKeys(selector.data ?? [])
        setErrors(selector.errors)
        setSuccess(selector.success)
        setLoading(selector.loading)
        setMeta(selector.meta)
        switch(selector.type)
        {
            case ActionType.KEY_DELETE:
            case ActionType.KEY_PUT:
            case ActionType.KEY_POST:
                getKeys()
        }
    }, [selector])

    return {
        getKeys,
        deleteKey,
        updateKey,
        addKey,
        errors,
        success,
        loading,
        meta,
        keys
    }
}

export default useKey