import { useContext, useEffect, useState } from 'react'
import { Table, Popconfirm, message } from "antd"
import { DeleteOutlined, CopyOutlined } from '@ant-design/icons'
import { FilterContext } from '../../../providers/FilterProvider'
import { Helmet } from 'react-helmet'
import Tariff from '../../../types/models/tariff'
import Spinner from '../../Spinner'
import useVPNBot from '../../../hooks/useVPNBot'

import './index.scss'

//TODO Add IColumns interface for table from newDataSource

const ItemsKeysTab = () =>
{
    //-------------------------------------------------------------------------------
    //                                  STATES
    //-------------------------------------------------------------------------------

    const [ messageApi, contextHolder ] = message.useMessage();
    const { usePagination } = useContext(FilterContext)
    const { useTariff, useItem, useServer } = useVPNBot()
    const { getPagination, setPagination } = usePagination
    const { tariffs, meta, getTariffs, deleteTariff, deleted } = useTariff
    const { item } = useItem

    const [visible, setVisible ] = useState<boolean>(false)
    const [dataSource, setDataSource] = useState<any[]>()
    const [fields, setFields] = useState({
        id: 0,
        name: '',
        price: 0,
        period: 0,
        invisible: false,
        servers: []
    })

    useEffect(() => {
        if (item)
            getTariffs(getPagination(), item?.name)
    }, [item])

    useEffect(() => {
        if (item && deleted)
            getTariffs(getPagination(), item?.name)
    }, [deleted])

    //-------------------------------------------------------------------------------
    //                                  EFFECTS
    //-------------------------------------------------------------------------------

    useEffect(() => {
        const newDataSource: Array<any> = [];

        tariffs?.map((tariff: Tariff, index: number) => {
            newDataSource.push({
                key:        index,
                id:         tariff.id,
                uuid:       tariff.uuid,
                username:   tariff?.client?.name,
                access_url: tariff?.key?.access_url,
                key_id:     tariff?.key?.id
            })
        })
        setDataSource(newDataSource)
    }, [tariffs])

    //-------------------------------------------------------------------------------
    //                                  HANDLERS
    //-------------------------------------------------------------------------------

    const onTariffDelete = (id: number) =>
    {
        deleteTariff(id)
    }

    const handleChange = (pagination: any, filters: any, sorter: any) =>
    {
        setPagination(pagination.current)
        getTariffs(pagination.current, item?.name, '')
    }

    const copyKey = (key: string) =>
    {
        navigator.clipboard.writeText(key)
        messageApi.open({
            type: 'success',
            content: `Key successfully copied! `,
        });
    }

    //-------------------------------------------------------------------------------
    //                                  TABLE COLUMNS
    //-------------------------------------------------------------------------------

    const columns = [
        {
            title: 'User',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'Key',
            dataIndex: 'access_url',
            key: 'access_url',
            render: (text: any, record: any) => <>
                { text && text.length ? <CopyOutlined onClick={() => copyKey(record.access_url)} className='copy-icon mr-3'/> : '' }
                { text ? text.substring(0,10) : '-'}
            </>
        },
        {
            title: 'Actions',
            key: 'id',
            width: 150,
            render: (text: any, record: any) => <>
                <Popconfirm
                    placement="top"
                    title={"Are you sure to delete this key?"}
                    onConfirm={() => onTariffDelete(record.id)}
                    okText="Yes"
                    cancelText="No"
                >
                    <DeleteOutlined className='delete-icon'/>
                </Popconfirm>
            </>
        }
    ];

    //-------------------------------------------------------------------------------
    //                                  RENDER
    //-------------------------------------------------------------------------------

    return (
        <>
            <Helmet>
                <title>Items - VPNBot Admin</title>
            </Helmet>
            {contextHolder}
            {
                (useItem.loading || useServer.loading || useTariff.loading) ?
                    <div className='flex justify-items-center items-center flex-col w-full'>
                        <Spinner/>
                    </div>
                :
                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        onChange={handleChange}
                        className={"items__tab"}
                        pagination={meta?.last_page === 1 ? false : {total: meta?.total, pageSize: meta?.per_page, current: getPagination()}}

                    />

            }
        </>
    )
}

export default ItemsKeysTab