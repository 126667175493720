
import { createContext } from 'react'
import useFilter from '../../hooks/useFilter'
import usePagination from '../../hooks/usePagination';

interface IHookProps
{
    useFilter: ReturnType<typeof useFilter>,
    usePagination: ReturnType<typeof usePagination>
}

const FilterContext = createContext({} as IHookProps);
const FilterProvider = ({ children }: any) => {
    const hookList = {
        useFilter: useFilter(),
        usePagination: usePagination()
    }
    return <FilterContext.Provider value={hookList} children={children} />
}

export { FilterProvider, FilterContext }