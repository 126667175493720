import AuthForm from "../../components/AuthForm";

const  Login = () => {
    return (
        <>
            <AuthForm/>
        </>
    )
}

export default Login;