import apiResponse from "../models/apiResponse"
import reducerState from "../models/reducerState"
import Purchase from "../models/purchase"

export enum ActionType{
    PURCHASE_LOADING   = 'PURCHASE_LOADING',
    PURCHASE_GET       = 'PURCHASE_GET',
    PURCHASE_GETS      = 'PURCHASE_GETS',
    PURCHASE_POST      = 'PURCHASE_POST',
    PURCHASE_PUT       = 'PURCHASE_PUT',
    PURCHASE_DELETE    = 'PURCHASE_DELETE',
    PURCHASE_ERROR     = 'PURCHASE_ERROR'
}

interface actionLoading
{
    type: ActionType.PURCHASE_LOADING
}

interface actionGet
{
    type: ActionType.PURCHASE_GET
}

interface actionGets
{
    type: ActionType.PURCHASE_GETS
}

interface actionPost
{
    type: ActionType.PURCHASE_POST
}

interface actionPut
{
    type: ActionType.PURCHASE_PUT
}

interface actionDelete
{
    type: ActionType.PURCHASE_DELETE
}

interface actionError
{
    type: ActionType.PURCHASE_ERROR
}

export type Action = actionLoading | actionGet | actionGets | actionPost | actionPut | actionDelete | actionError;

export type State = reducerState & apiResponse<Purchase[]>;

export const initialState: State =
{
    type: undefined,
    data: [],
    loading: false,
    success: false
}
