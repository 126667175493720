import { useState, useEffect } from 'react'
import { Button, message } from "antd"
import { PlusOutlined } from '@ant-design/icons'
import ServersModal from '../../Modal/ServersModal'
import useVPNBot from '../../../hooks/useVPNBot'
import Server from '../../../types/models/server'

import './index.scss'

const ServersMenu = () =>
{
    const { useServer, useCountry } = useVPNBot()

    const { addServer, errors, success, loading } = useServer

    const [messageApi, contextHolder] = message.useMessage()
    const [visible, setVisible] = useState(false)
    const [fields, setFields] = useState<any>({
        name: '',
        short_name: '',
        provider: '',
        limit: 0,
        country: 'Russian Federation',
        info: '',
    })

    const onAddServer = () =>
    {
        const server = { ...fields } as Server
        addServer(server, fields.info)
        setVisible(false)
    }

    const onFieldChange = (name: string, value: any) =>
    {
        setFields({
            ...fields,
            [name]: value
        })
    }

    useEffect(() => {
        if (success) setVisible(false)
    }, [loading])

    useEffect(() => {
        messageApi.destroy()
        errors?.map((value: string, index: number) => {
            messageApi.open({
                type: 'error',
                content: value,
            });
        });
    }, [])

    return <>
        {contextHolder}
        <Button className="flex items-center" onClick={() => setVisible(true)} type="primary" disabled={useCountry.loading || useServer.loading}><PlusOutlined /> Add server</Button>
        <ServersModal
            title={'Add server'}
            buttonTitle={'Add'}
            source={fields}
            show={visible}
            handleShow={(state: boolean) => setVisible(state)}
            handleSource={onFieldChange}
            handleApply={onAddServer}
        />
    </>
}

export default ServersMenu