import { Action, ActionType, initialState, State } from '../types/actions/client';

const clientReducer = (state: State = initialState, action: Action): State =>
{
    //console.log({...state, ...action})
    switch(action.type)
    {
        case ActionType.CLIENT_DELETE:
        case ActionType.CLIENT_ERROR:
        case ActionType.CLIENT_POST:
        case ActionType.CLIENT_GET:
        case ActionType.CLIENT_GETS:
        case ActionType.CLIENT_LOADING:
        case ActionType.CLIENT_PUT:
            return {...state, ...action};
        default:
            return state;
    }
}

export default clientReducer;