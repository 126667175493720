import { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom"

interface IBreadcrumb
{
    title: string,
    href?: string
}

const useBreadcrumbs = () =>
{
    const location = useLocation()
    const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>([])

    useEffect(() => {
        const items = [] as IBreadcrumb[]
        const path = location.pathname.split('/')
        path.shift()
        path.map((url, index) => {
            const previousLink = path[index - 1] ?? undefined
            const link = previousLink ? `/${previousLink}/` : '/'
            const title = decodeURI(url)
            items.push({
                title: (title[0] && title[0].toUpperCase()) + title.slice(1),
                href: link + url
            })
        })
        setBreadcrumbs(items.length > 1 ? items : [])
    }, [location])

    return {
        breadcrumbs,
        setBreadcrumbs
    }
}

export default useBreadcrumbs