import { AppDispatch, AppThunk } from '../store'
import { ActionType } from '../types/actions/promocode'
import { getPage, addPromocode, deletePromocode, editPromocode } from '../api/promocodeAPI'
import Promocode from '../types/models/promocode'


const getPromocodes = (page?: number): AppThunk => (dispatch: any) =>
{
    dispatch({
        loading: true,
        type: ActionType.PROMOCODE_LOADING
    })
    getPage(page).then((response) => {
        const promocodeResponse = response.data as Promocode[]
        dispatch({
            loading: false,
            errors: [],
            type: ActionType.PROMOCODE_GETS,

            ...promocodeResponse
        });
    }).catch((exception) => {
        delete exception.response.data.data
        dispatch({
            loading: false,
            type: ActionType.PROMOCODE_ERROR,
            ...exception.response.data
        })
    });
}

const addPromocodeAction = (promocode: Promocode): AppThunk => (dispatch: any) =>
{
    dispatch({
        loading: true,
        type: ActionType.PROMOCODE_LOADING
    })
    addPromocode(promocode).then((response) => {
        const promocodeResponse = response.data as Promocode[]
        dispatch({
            loading: false,
            errors: [],
            type: ActionType.PROMOCODE_POST,
            ...promocodeResponse
        });
    }).catch((exception) => {
        delete exception.response.data.data
        dispatch({
            loading: false,
            type: ActionType.PROMOCODE_ERROR,
            ...exception.response.data
        })
    });
}

const deletePromocodeAction = (id: Promocode["id"]): AppThunk => (dispatch: any) =>
{
    dispatch({
        loading: true,
        type: ActionType.PROMOCODE_LOADING
    })
    deletePromocode(id).then((response) => {
        const promocodeResponse = response.data as Promocode[]
        dispatch({
            loading: false,
            errors: [],
            type: ActionType.PROMOCODE_DELETE,
            ...promocodeResponse
        });
    }).catch((exception) => {
        delete exception.response.data.data
        dispatch({
            loading: false,
            type: ActionType.PROMOCODE_ERROR,
            ...exception.response.data
        })
    });
}

const editPromocodeAction = (promocode: Promocode): AppThunk => (dispatch: any) =>
{
    dispatch({
        loading: true,
        type: ActionType.PROMOCODE_LOADING
    })
    editPromocode(promocode).then((response) => {
        const promocodeResponse = response.data as Promocode[]
        dispatch({
            loading: false,
            errors: [],
            type: ActionType.PROMOCODE_DELETE,
            ...promocodeResponse
        });
    }).catch((exception) => {
        delete exception.response.data.data
        dispatch({
            loading: false,
            type: ActionType.PROMOCODE_ERROR,
            ...exception.response.data
        })
    });
}

export { getPromocodes, addPromocodeAction, deletePromocodeAction, editPromocodeAction }