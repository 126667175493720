import { Layout } from "antd";
import Nav from "../../components/Nav";
import Header from "../../components/Header";
import Tab from "../../components/Tab";
import { Helmet } from "react-helmet";

const  Dashboard = () => {
    return (
        <>
            <Helmet>
                <title>Dashboard - VPNBot Admin</title>
            </Helmet>
            <Layout style={{ minHeight: '100vh' }}>
                <Header/>
                <Layout>
                    <Nav/>
                    <Tab/>
                </Layout>
            </Layout>
        </>
    )
}

export default Dashboard;