import { useState, useEffect, useContext } from 'react'
import { FilterContext } from '../providers/FilterProvider'
import { getServersAction, addServerAction, updateServersAction, deleteServersAction, getServerAction } from '../actions/server'
import { ActionType } from '../types/actions/server'
import { useAppDispatch } from './useAppDispatch'
import { useAppSelector } from './useAppSelector'
import Meta from '../types/models/meta'
import Server from '../types/models/server'
import Item from '../types/models/item'


const useServer = () =>
{
    const { usePagination } = useContext(FilterContext)
    const { getPagination } = usePagination
    const selector = useAppSelector((state) => state.servers)
    const dispatch = useAppDispatch()
    const [meta, setMeta] = useState<Meta|undefined>(selector?.meta)
    const [loading, setLoading] = useState(selector.loading)
    const [servers, setServers] = useState<Server[]>()
    const [server, setServer] = useState<Server>()
    const [errors, setErrors] = useState(selector.errors)
    const [success, setSuccess] = useState(selector.success)

    useEffect(() => {
        setServers(selector?.data)
        setServer(selector?.data ? selector?.data[0]: server)
        setMeta(selector?.meta)
        setLoading(selector.loading)
        setErrors(selector.errors)
        setSuccess(selector.success)
        switch (selector.type)
        {
            case ActionType.SERVER_DELETE:
            case ActionType.SERVER_POST:
            case ActionType.SERVER_PUT:
                getServers()
        }
    }, [selector])

    const getServer = (id: number) =>
    {
        dispatch(getServerAction(id))
    }

    const getServers = (item?: Item["name"], page?: number) =>
    {
        dispatch(getServersAction(item, page ?? getPagination()))
    }

    const addServer = (server: Server, info: string) =>
    {
        dispatch(addServerAction(server, info))
    }

    const updateServer = (server: Server, info: string) =>
    {
        dispatch(updateServersAction(server, info))
    }

    const deleteServer = (id: number) =>
    {
        dispatch(deleteServersAction(id))
    }

    return {
        getServers,
        getServer,
        addServer,
        updateServer,
        deleteServer,
        loading,
        servers,
        server,
        meta,
        errors,
        success
    }
}

export default useServer