import axios from 'axios'

const request = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URL,
    responseType: "json"
});

const telegramRequest = axios.create({
    baseURL: process.env.REACT_APP_TELEGRAM_API_URL,
    responseType: "json"
});

export { request, telegramRequest }
