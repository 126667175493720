import Spinner from "../Spinner";


interface LoaderProps{
    visible?: boolean
}

const Loader = ({visible}: LoaderProps) =>
{
    const opacity = visible ? 'opacity-100' : 'opacity-0'

    return (
        <div className={`w-full h-full bg-black bg-opacity-80 bottom-0 fixed flex justify-center items-center transition-opacity ${opacity} pointer-events-none`}>
            <Spinner/>
        </div>
    )
}

export default Loader;