import apiResponse from "../models/apiResponse"
import reducerState from "../models/reducerState"
import Country from "../models/country"

export enum ActionType{
    COUNTRY_LOADING   = 'COUNTRY_LOADING',
    COUNTRY_GET       = 'COUNTRY_GET',
    COUNTRY_GETS      = 'COUNTRY_GETS',
    COUNTRY_POST      = 'COUNTRY_POST',
    COUNTRY_PUT       = 'COUNTRY_PUT',
    COUNTRY_DELETE    = 'COUNTRY_DELETE',
    COUNTRY_ERROR     = 'COUNTRY_ERROR'
}

interface actionLoading
{
    type: ActionType.COUNTRY_LOADING
}

interface actionGet
{
    type: ActionType.COUNTRY_GET
}

interface actionGets
{
    type: ActionType.COUNTRY_GETS
}

interface actionPost
{
    type: ActionType.COUNTRY_POST
}

interface actionPut
{
    type: ActionType.COUNTRY_PUT
}

interface actionDelete
{
    type: ActionType.COUNTRY_DELETE
}

interface actionError
{
    type: ActionType.COUNTRY_ERROR
}

export type Action = actionLoading | actionGet | actionGets | actionPost | actionPut | actionDelete | actionError;

export type State = reducerState & apiResponse<Array<string>>;

export const initialState: State =
{
    type: undefined,
    data: undefined,
    loading: false,
    success: true,
    errors: [],
}
