import { Button, Checkbox, Col, Form, Input, Modal, Row, Select, Space } from "antd"
import Server from '../../../types/models/server'
import useVPNBot from "../../../hooks/useVPNBot"
import { useEffect } from "react"

interface ISource
{
    name: string,
    price: number,
    period: number,
    servers: Array<string>,
    invisible: boolean,
}

interface IProps
{
    title: string,
    buttonTitle: string,
    show: boolean,
    handleShow: ((state: boolean) => any)
    source: ISource,
    handleSource: ((name: string, value: any) => any),
    handleApply: (() => void)
}


const ItemsModal = ({title, buttonTitle, show, handleShow, source, handleSource, handleApply}: IProps) =>
{
    const { useItem, useServer } = useVPNBot()
    const { servers } = useServer
    const { Option } = Select
    const [form] = Form.useForm()

    useEffect(() => form.setFieldsValue(source), [source])

    return (
        <Modal
            title={title}
            centered
            open={show}
            onOk={form.submit}
            onCancel={() => handleShow(false)}
            footer={[
                <Button key="cancel" onClick={() => handleShow(false)}>
                    Cancel
                </Button>,
                <Button form={title} key="submit" htmlType="submit">
                    {buttonTitle}
                </Button>
            ]}
        >
            <Form
                name={title}
                autoComplete="off"
                layout="vertical"
                id={title}
                form={form}
                onFinish={handleApply}
                initialValues={source}
            >
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label="Name"
                            name="name"
                            rules={[{ required: true, message: 'Please input itemname' }]}
                        >
                            <Input name='name' value={source.name} onChange={e => handleSource(e.target.name, e.currentTarget.value)} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item
                            label="Price"
                            name="price"
                            className="pr-1"
                        >
                            <Input type="number" name='price' value={source.price} onChange={e => handleSource(e.target.name, e.currentTarget.value)} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Period"
                            name="period"
                            className="pl-1"
                        >
                            <Input type="number" name='period' value={source.period} onChange={e => handleSource(e.target.name, e.currentTarget.value)} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label="Servers"
                            name="servers"
                            rules={[{ required: true, message: 'Please select servers' }]}
                        >
                            <Select
                                mode="multiple"
                                value={{value: source.servers, label: source.servers}}
                                onChange={(value: any) => handleSource('servers', value)}
                            >
                                {servers?.map((server: Server, id: number) =>
                                    <Option value={server.name} text={server.name} key={id}>
                                        <span>{server.name}</span><span className="float-right"></span>
                                    </Option>
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name="invisible"
                        >
                            <Checkbox name="invisible" onChange={e => handleSource("invisible", e.target.checked)} checked={source.invisible}>Invisible</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>)
}

export default ItemsModal