import { AppDispatch, AppThunk } from '../store'
import { ActionType } from '../types/actions/purchase'
import { addPurchase, getPurchases, getPurchaseByTrace } from '../api/purchaseAPI'
import Item from '../types/models/item'

const getPurchasesAction = (item?: Item["name"], page?: number, date?: string): AppThunk => (dispatch: any) =>
{
    dispatch({
        loading: true,
        type: ActionType.PURCHASE_LOADING
    })
    getPurchases(item, page, date).then((response) => {
        dispatch({
            loading: false,
            type: ActionType.PURCHASE_GETS,
            data: response.data.data,
            meta: response.data.meta
        })
    }).catch((exception) => {
        dispatch({
            loading: false,
            type: ActionType.PURCHASE_ERROR,
            errors: [exception.message]
        })
    })
}

const addPurchaseAction = (nickname: string, key: string): AppThunk => (dispatch: any) =>
{
    dispatch({
        loading: true,
        type: ActionType.PURCHASE_LOADING
    })
    addPurchase(nickname, key).then((response) => {
        dispatch({
            loading: false,
            type: ActionType.PURCHASE_POST,
            data: response.data.data,
            meta: response.data.meta
        })
    }).catch((exception) => {
        delete exception.response.data.data
        dispatch({
            loading: false,
            type: ActionType.PURCHASE_ERROR,
            ...exception.response.data
        })
    })
}

const getPurchaseByTraceAction = (trace: string): AppThunk => (dispatch: any) =>
{
    dispatch({
        loading: true,
        type: ActionType.PURCHASE_LOADING
    })
    getPurchaseByTrace(trace).then((response) => {
        dispatch({
            loading: false,
            type: ActionType.PURCHASE_GET,
            data: [response.data.data],
            meta: response.data.meta
        })
    }).catch((exception) => {
        delete exception.response.data.data
        dispatch({
            loading: false,
            type: ActionType.PURCHASE_ERROR,
            ...exception.response.data
        })
    })
}

export { getPurchasesAction, addPurchaseAction, getPurchaseByTraceAction }