import { Dropdown, Layout, MenuProps, Skeleton } from "antd";
import { LogoutOutlined, DownOutlined,  UserOutlined } from '@ant-design/icons'
import useVPNBot from "../../hooks/useVPNBot"

import './index.scss'
import Logo from "../Logo";

const Header = () => {

    const { useUser } = useVPNBot()

    const {logout, username } = useUser

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
              <a href="#">Profile</a>
            ),
            icon: <UserOutlined />
        },
        {
            key: '2',
            label: (
                <a className="text-red" onClick={e => {e.preventDefault();logout()}}>Logout</a>
            ),
            icon: <LogoutOutlined className="text-red" />
        },
    ]

    return (
    <Layout.Header className="w-full">
        <div className="mr-auto">
            <Logo/>
        </div>
        <Dropdown menu={{ items }} trigger={['click']} className="justify-self-end">
            <span>{username ? username : <Skeleton.Button size="small" active />} <DownOutlined /></span>
        </Dropdown>
    </Layout.Header>
    )
}

export default Header