import { Action, ActionType, initialState, State } from '../types/actions/purchase';

const purchaseReducer = (state: State = initialState, action: Action): State =>
{
    switch(action.type)
    {
        case ActionType.PURCHASE_LOADING:
        case ActionType.PURCHASE_POST:
        case ActionType.PURCHASE_ERROR:
        case ActionType.PURCHASE_GET:
        case ActionType.PURCHASE_GETS:
        case ActionType.PURCHASE_PUT:
        case ActionType.PURCHASE_DELETE:
            return {...state, ...action};
        default:
            return state;
    }
}

export default purchaseReducer;